import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { FaArrowLeft } from "react-icons/fa";
import Navbar from "../components/Navbar";
import { auth, db } from "../firebase";
import Loader from "../components/Loader";

const RecordingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [recording, setRecording] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecording = async () => {
      setIsLoading(true);
      const user = auth.currentUser;

      if (!user) {
        console.error("No authenticated user found");
        setIsLoading(false);
        return;
      }

      const userUid = user.uid;
      const userRef = doc(db, "History", userUid);
      try {
        const docRef = doc(userRef, "RecordingHistory", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setRecording(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching recording:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecording();
  }, [id]);

  console.log("video url ", recording);

  if (isLoading) return <Loader />;

  if (!recording) return <div>No recording found</div>;

  return (
    <div className="text-gray-100">
      <section className="bg-secondaryColor px-[10%] py-[6%] flex flex-col items-center h-screen">
        <Navbar />
        <div className="w-full flex justify-start">
          <button
            onClick={() => navigate("/settings", { state: { activeTab: "recording" } })}
            className="text-white hover:text-primaryColor"
          >
            <FaArrowLeft size={20} />
          </button>
        </div>
        <div className="w-full gap-4 flex-wrap flex justify-center">
          <div className="bg-white p-10 rounded-lg relative">
            <video
              width={800}
              height={500}
              controls
              src={recording.recordingUrl}
              className="rounded-xl"
            ></video>
            <div className="p-2">
              <h2 className="font-bold text-lg mb-2 text-primaryColor">
                {recording.recordingName}
              </h2>
              <p className="text-sm text-black">
                {new Date(recording.timestamp).toLocaleString("en-US", {
                  timeZone: "UTC",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: false,
                })}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecordingDetail;
