import "@stripe/stripe-js";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import React, { useState, useRef } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./pages/LandingPage";
import PricingPage from "./pages/PricingPage";
import Setting from "./pages/Setting";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import SuccessPage from "./pages/SuccessPage";
import StartRecordingScreen from "./pages/StartRecordingScreen";
import ViewRecordingScreen from "./pages/ViewRecordingScreen";
import RecordingDetail from "./pages/RecordingDetail";
import StopRecordingScreen from "./pages/StopRecordingScreen";
import PrivacyPolicy from "./pages/PrivacyAndPolicy";
import TermsAndConditions from "./pages/TermsAndCondition";

function App() {
  function mixAudioTracks(audioTracks) {
    const audioContext = new AudioContext();
    const dest = audioContext.createMediaStreamDestination();
    audioTracks.forEach((track) => {
      const source = audioContext.createMediaStreamSource(
        new MediaStream([track])
      );
      source.connect(dest);
    });
    return dest.stream;
  }

  const [recording, setRecording] = useState(false);
  const [enableWebcam, setEnableWebcam] = useState(false);
  const [enableDesktopAudio, setEnableDesktopAudio] = useState(false);
  const [enableMicrophoneAudio, setEnableMicrophoneAudio] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const [videoBlob , setVideoBlob] = useState();

  const startRecording = async () => {
    setEnableWebcam(false);
    setEnableDesktopAudio(false);
    setEnableMicrophoneAudio(false);
    try {
      const combinedTracks = [];
      recordedChunksRef.current = [];

      let webcamStream = null;
      if (enableWebcam) {
        webcamStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const webcamVideo = document.createElement("video");
        webcamVideo.srcObject = webcamStream;
        webcamVideo.muted = true;
        await webcamVideo.play();
        await webcamVideo.requestPictureInPicture();
      }

      let desktopStream;
      if (enableDesktopAudio) {
        desktopStream = await navigator.mediaDevices.getDisplayMedia({
          video: { displaySurface: "monitor" },
          audio: true,
        });
        combinedTracks.push(...desktopStream.getTracks());

        var microphoneStream;
        if (enableMicrophoneAudio) {
          microphoneStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          const mixedAudioStream = mixAudioTracks([
            ...desktopStream.getAudioTracks(),
            ...microphoneStream.getAudioTracks(),
          ]);
          combinedTracks.push(...mixedAudioStream.getTracks());
        }
      }

      let combinedStream = new MediaStream(combinedTracks);
      if (combinedStream.getTracks().length === 0) {
        throw new Error("No tracks to record.");
      }

      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          recordedChunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = () => {
        if (recordedChunksRef.current.length > 0) {
          const blob = new Blob(recordedChunksRef.current, {
            type: "video/webm",
          });

          setVideoBlob(blob)


          const url = URL.createObjectURL(blob);
          setVideoURL(url);
        }

        setRecording(false);

        if (combinedStream) {
          combinedStream.getTracks().forEach((track) => track.stop());
          combinedStream = null;
        }
        if (desktopStream) {
          desktopStream.getTracks().forEach((track) => track.stop());
          desktopStream = null;
        }
        if (microphoneStream) {
          microphoneStream.getTracks().forEach((track) => track.stop());
          microphoneStream = null;
        }
        if (webcamStream) {
          webcamStream.getTracks().forEach((track) => track.stop());
          webcamStream = null;
        }
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = async () => {
    try {
      if (mediaRecorderRef.current && recording) {
        mediaRecorderRef.current.stop();
        setRecording(false);
      }

      // Exiting PiP mode if active
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
        // console.log("Exited PiP mode.");
      }
    } catch (error) {
      console.error("Error stopping the recording or stream:", error);
    }
  };

  return (
    <UserAuthContextProvider>
      <HashRouter>
   
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route
            path="/recording"
            element={
              <StartRecordingScreen
                enableWebcam={enableWebcam}
                enableDesktopAudio={enableDesktopAudio}
                enableMicrophoneAudio={enableMicrophoneAudio}
                handleToggleWebcam={() => setEnableWebcam((prev) => !prev)}
                handleToggleDesktopAudio={() =>
                  setEnableDesktopAudio((prev) => !prev)
                }
                handleToggleMicrophoneAudio={() =>
                  setEnableMicrophoneAudio((prev) => !prev)
                }
                handleStartRecording={startRecording}
              />
            }
          />
          <Route path="/settings/:id" element={<RecordingDetail />} />
          <Route
            path="/view-recording"
            element={<ViewRecordingScreen videoURL={videoURL} videoBlob={videoBlob} />}
          />
          <Route
            path="/recording-stop"
            element={
              <StopRecordingScreen handleStopRecording={stopRecording} />
            }
          />
        </Routes>
        <ToastContainer />
     
      </HashRouter>
    </UserAuthContextProvider>
  );
}

export default App;
