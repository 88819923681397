import React from "react";
import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaTwitch,
  FaLinkedin,
} from "react-icons/fa";
import logo from "../Images/logo.png";
import { Link } from "react-router-dom";

const sections = [
  {
    title: "Solutions",
    items: ["Home", "Screen Recording", "Pricing"],
  },
  {
    title: "Company",
    items: ["About", "Blog", "Jobs"],
  },
  {
    title: "Support",
    items: ["Contact us", "Help"],
  },
  {
    title: "Legal",
    items: ["Privacy", "Terms"],
  },
];

const items = [
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/uzrtech/" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/uzrtech/" },
  { name: "LinkedIn", icon: FaLinkedin, link: "https://pk.linkedin.com/company/uzrtech" },
  { name: "Github", icon: FaGithub, link: "https://github.com/Uzair-Technology" },
];

const routes = {
  Home: "/",
  "Screen Recording": "/recording",
  Pricing: "/pricing",
  About: "/about",
  Blog: "/blog",
  Jobs: "/jobs",
  "Contact us": "/contact-us",
  Help: "/help",
  Privacy: "/privacy",
  Terms: "/terms",
};

const Footer = () => {
  return (
    <div className="w-full bg-slate-900 text-gray-300 py-y px-2">
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
        <div className="col-span-2 pt-8 md:pt-2">
          <Link to="/">
            <img src={logo} alt="logo" className="h-10" />
          </Link>
          <h1 className="font-bold mt-2">
            <span className="text-primaryColor text-lg">UZR</span> Screen Recorder
          </h1>
        </div>
        {sections.map((section, index) => (
          <div key={index}>
            <h6 className="font-bold uppercase pt-2">{section.title}</h6>
            <ul>
              {section.items.map((item, i) => (
                <li key={i} className="py-1 text-gray-500 hover:text-white">
                  <Link to={routes[item]}>{item}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">Copyright 2024 &copy; UZR Technology</p>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
          {items.map((x, index) => (
            <a key={index} href={x.link} target="_blank" rel="noopener noreferrer">
              <x.icon className="hover:text-white" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
