import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { collection, doc, getDocs } from "firebase/firestore";
import Loader from "../components/Loader";

const PaymentHistory = () => {
  const [payHistory, setPayHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      setIsLoading(true);
      const userUid = auth.currentUser.uid;
      const userRef = doc(db, "History", userUid);
      try {
        const paysSnapshot = await getDocs(
          collection(userRef, "PaymentHistory")
        );
        const payList = paysSnapshot.docs.map((doc) => doc.data());
        setPayHistory(payList);
      } catch (error) {
        console.error("Error fetching payment history:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentHistory();
  }, []);

  return (
    <div className="">
      <table className="table-auto w-full">
        <thead className="border border-primaryColor ">
          <tr>
            <th className="border border-primaryColor px-6 py-2">ID</th>
            <th className="border border-primaryColor">Amount</th>
            <th className="border border-primaryColor">Total Credits</th>
            <th className="border border-primaryColor">Status</th>
            <th className="border border-primaryColor">Created At</th>
          </tr>
        </thead>

        <tbody className="text-center">
          {isLoading ? (
            <Loader />
          ) : payHistory.length > 0 ? (
            payHistory.map((pay, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{pay.Amount}</td>
                <td>{pay.Credits}</td>
                <td>{pay.paymentStatus}</td>
                <td>
                  {new Date(pay.timestamp).toLocaleString("en-US", {
                    timeZone: "UTC",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  })}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No data to load</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentHistory;
