import React, { useState, useEffect } from "react";
import Ellipse from "../Images/Ellipse.png";
import Wave3 from "../Images/wave3.png";
import RecordingImage from "../Images/screenRecorderCard1.webp";
import RecordingImage2 from "../Images/img_1.webp";
import Wave1 from "../Images/wave.png";
import Navbar from "../components/Navbar";
import Accordion from "../components/Accordion";
import { questions } from "../data/question";
import Footer from "../components/Footer";
import Loader from "../components/Loader"; // Ensure you have a Loader component
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [cards] = useState(questions);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="text-gray-100">
      {isLoading ? (
        <Loader /> // Display the loader while the page is loading
      ) : (
        <>
          <Navbar />
          <section className="bg-secondaryColor h-screen py-20">
            <img
              src={Ellipse}
              alt="Ellipse"
              className="z-20 animate-ping w-6 absolute left-24 top-56 md:left-auto md:right-96 md:top-36"
            />
            <img
              src={Ellipse}
              alt="Ellipse"
              className="z-20 animate-ping w-6 absolute left-64 bottom-24 md:left-auto md:right-40 md:bottom-auto md:top-64"
            />
            <img
              src={Wave3}
              alt="Wave"
              className=" absolute bottom-20 right-36 w-96 hidden md:block"
            />
            <img
              src={Wave1}
              alt="Wave"
              className="absolute  bottom-0 right-0 w-2/3 hidden md:block"
            />

            <div className="text-center md:text-left my-20  max-w-[1240px] mx-auto">
              <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold my-10 w-full md:w-3/4 lg:w-1/2">
                Capture and Share Your Moments with Ease Screen Recorder
              </h1>
              <p className="text-sm text-gray-400 my-10 max-w-prose">
                Unleash Your Creativity with the Ultimate Screen Recording
                Experience. Capture, Share, and Inspire.
              </p>
              <button className="bg-primaryColor px-7 rounded-full tracking-wide py-3 text-lg hover:scale-110 duration-300 inline-block">
                <Link to="recording">GET STARTED</Link>
              </button>
            </div>
          </section>

          <section className="bg-secondaryColor text-black py-20">
            <div className="max-w-[1240px] mx-auto flex flex-col-reverse lg:flex-row justify-center items-center px-6 lg:px-0">
              <div className="lg:w-1/2 flex justify-center items-center">
                <div>
                  <h1 className="text-white text-4xl font-bold mb-6 text-center lg:text-left">
                    Screen and camera recording tips
                  </h1>
                  <ul className="text-white flex flex-col gap-8">
                    <li>
                      Resize your screen recording to vertical or horizontal
                      aspect ratios to suit any platform like YouTube, LinkedIn,
                      or social media stories.
                    </li>
                    <li>
                      Customize the layout of your video by freely rearranging
                      webcam footage so viewers never miss important screen
                      information.
                    </li>
                    <li>
                      Personalize screen recordings for social media with like
                      and subscribe animations, notification stickers, emojis,
                      and GIFs.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lg:w-1/2 flex justify-center items-center mb-8 lg:mb-0">
                <img
                  src={RecordingImage2}
                  alt="Recording Tips"
                  className="max-w-full h-auto"
                />
              </div>
            </div>
          </section>

          <section className="bg-secondaryColor text-black py-20">
            <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row justify-center items-center px-6 lg:px-0">
              <div className="lg:w-1/2 flex justify-center items-center mb-8 lg:mb-0">
                <img
                  src={RecordingImage}
                  alt="Recording Options"
                  className="max-w-full h-auto"
                />
              </div>
              <div className="lg:w-1/2 flex justify-center items-center">
                <div>
                  <h1 className="text-white text-4xl font-bold text-center lg:text-left">
                    Record with Multiple Options
                  </h1>
                  <p className="text-white text-xl my-6 text-center lg:text-left">
                    Record your screen and webcam simultaneously or separately
                    as you want. You can also choose to make a screen capture of
                    the whole screen, application windows, or browser tab.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-secondaryColor text-black py-20">
            <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center px-6 lg:px-0">
              <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                <h1 className="text-white text-3xl text-center lg:text-left">
                  FREQUENTLY ASKED QUESTIONS
                </h1>
              </div>
              <div className="w-full lg:w-1/2 flex flex-col gap-8 mx-auto">
                {cards.map((card, index) => (
                  <Accordion {...card} key={index} />
                ))}
              </div>
            </div>
          </section>

          <Footer />
        </>
      )}
    </div>
  );
};

export default LandingPage;
