import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { FaMicrophone, FaMusic } from "react-icons/fa";
import { BsFillWebcamFill } from "react-icons/bs";

const StartRecordingScreen = ({
  enableWebcam,
  enableDesktopAudio,
  enableMicrophoneAudio,
  handleToggleWebcam,
  handleToggleDesktopAudio,
  handleToggleMicrophoneAudio,
  handleStartRecording,
}) => {
  const navigate = useNavigate();

  const startRecording = () => {
    handleStartRecording();
    navigate("/recording-stop");
  };

  return (
    <div className="text-gray-100">
      <section className="bg-secondaryColor min-h-screen flex flex-col md:flex-row items-center px-[10%] py-[6%]">
        <Navbar />
      
        <div className="flex-1 mt-10 md:mt-0">
          <h1 className="font-semibold text-4xl md:text-5xl text-center md:text-left text-gray-100 tracking-tight mt-10">
            Recording Screen
          </h1>
         
          <div className="flex flex-col md:flex-row gap-4 my-10 justify-center md:justify-start">
            <div
              className={`flex border-4 p-10 rounded-lg relative text-2xl ${
                enableMicrophoneAudio ? "bg-primaryColor" : "bg-transparent"
              }`}
            >
              <label>
                <input
                  type="checkbox"
                  className="absolute top-[-9999px] left-[-9999px] w-6 h-6 cursor-pointer"
                  checked={enableMicrophoneAudio}
                  onChange={handleToggleMicrophoneAudio}
                />
                <div className="flex gap-4">
                  <FaMicrophone />
                  Microphone
                </div>
              </label>
            </div>

            <div
              className={`flex border-4 p-10 rounded-lg relative text-2xl ${
                enableDesktopAudio ? "bg-primaryColor" : "bg-transparent"
              }`}
            >
              <label>
                <input
                  type="checkbox"
                  className="absolute top-[-9999px] left-[-9999px]"
                  checked={enableDesktopAudio}
                  onChange={handleToggleDesktopAudio}
                />
                <div className="flex gap-4">
                  <FaMusic />
                  Desktop Audio
                </div>
              </label>
            </div>

            <div
              className={`flex border-4 p-10 rounded-lg relative text-2xl ${
                enableWebcam ? "bg-primaryColor" : "bg-transparent"
              }`}
            >
              <label>
                <input
                  type="checkbox"
                  className="absolute top-[-9999px] left-[-9999px]"
                  checked={enableWebcam}
                  onChange={handleToggleWebcam}
                />
                <div className="flex gap-4">
                  <BsFillWebcamFill />
                  Webcam
                </div>
              </label>
            </div>
          </div>

          <div className="my-10 flex justify-center md:justify-start">
            <button
              onClick={startRecording}
              className="bg-primaryColor px-7 rounded-full tracking-wide py-3 text-lg hover:scale-110 duration-300"
            >
              START RECORDING
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StartRecordingScreen;
