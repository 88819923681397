export const questions = [
  {
    question: "How do I use the screen recorder?",
    answer:
     "Simple! Just choose your layout (screen only, webcam only, or both) and hit the red button. You can record JUST your screen or have your webcam record a video of you in the top right corner, for example, simultaneously. You will see a countdown timer then the screen capture will start. To stop, just hit the red button again. Easy!",
  },
  {
    question: "How longcan I record for?",
    answer:
      "You can record up to one (1) hour per recording session.",
  },
  {
    question: "Can I record my screen with audio?",
    answer:
      "You can record the microphone and system audio according to your needs.",
  },
  {
    question: "How can I get support if I met problems while recording?",
    answer:
      "You can reach out to us via contact form or Livechat. We will help you solve problems as soon as possible.",
  },


];
