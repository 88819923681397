import React from "react";
import { useNavigate } from "react-router-dom";
import Wave1 from "../Images/wave.png";
import Navbar from "../components/Navbar";
import ScreenRecordingImage from "../Images/screenRecordingImage.png";

const StopRecordingScreen = ({ handleStopRecording }) => {
  const navigate = useNavigate();

  const stopRecording = () => {
    handleStopRecording();
    navigate("/view-recording");
  };

  return (
    <div className="text-gray-100">
      <section className="bg-secondaryColor min-h-screen flex flex-col md:flex-row items-center md:justify-center gap-10 md:gap-20 px-4 md:px-10 py-6 md:py-8">
        {/* Navbar */}
        <Navbar />

        {/* Wave Image */}
        <img
          src={Wave1}
          alt="Ellipse"
          className="absolute z-20 bottom-0 right-0 w-full md:w-2/3 hidden md:block"
        />

        <div className="flex-1 mt-20 md:mt-0">
          <div className="w-full md:w-full md:mr-4 mt-8 md:mt-0">
            <img
              src={ScreenRecordingImage}
              alt="Screen Recording"
              width={600}
            />
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 flex flex-col md:flex-row  items-center md:items-center">
          {/* Screen Recording Image */}

          {/* Button */}
          <div className="my-4 md:my-10">
            <button
              onClick={stopRecording}
              className="bg-primaryColor px-6 md:px-7 rounded-full tracking-wide py-3 md:text-lg lg:text-xl hover:scale-110 duration-300"
            >
              STOP RECORDING
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StopRecordingScreen;
