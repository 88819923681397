import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyDH1ZoLXZJfeedzSiRwSpV-5OGa1uIAcMk",
  authDomain: "uzr-screen-recorder-ca059.firebaseapp.com",
  projectId: "uzr-screen-recorder-ca059",
  storageBucket: "uzr-screen-recorder-ca059.appspot.com",
  messagingSenderId: "337871425812",
  appId: "1:337871425812:web:47ff494b1531e945c97201",
  measurementId: "G-JFVMNPVLD6"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const imgDb = getStorage(app);
