import React from 'react';

const Loader = () => {
  return (
    <div className="top-0 left-0 flex items-center justify-center fixed text-center bg-[#d8d8d85e] w-[100%] h-[100%] z-50 ">
    <div
      className="flex flex-col justify-center items-center  z-50 w-[350px] h-[200px] drop-shadow-shadow  bg-white rounded-md p-8 capitalize
      md:w-[400px]  md:h-[300px] 
     "
    >
      <p className=" mt-4 text-xl font-semibold mb-4 text-secondaryColor">Please Wait!</p>
      <div
        className="animate-spin rounded-full border-t-4 border-primaryColor border-opacity-100 border-solid h-10 w-10
         md:h-12 md:w-12 z-50"
      ></div>
      <p className=" mt-4 text-sm text-black">Loading ...</p>
    </div>
  </div>
  )
}


export default Loader;
