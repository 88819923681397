import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Account from "./Account";
import RecordingHistory from "./RecordingHistory";
import PaymentHistory from "./PaymentHistory";
import Navbar from "../components/Navbar";
import { useUserAuth } from "../context/UserAuthContext";
import { toast } from "react-toastify";

const Setting = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("account");
  const { user } = useUserAuth(); 
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/signin");
      toast.error("User Account Required!", {
        position: "top-center",
      });
      return;
    }
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="text-gray-100">
      <section className="bg-secondaryColor min-h-screen flex flex-col md:flex-row px-[10%] py-[6%]">
        <Navbar />

        <div className="flex-1">
          <h1 className="font-semibold text-4xl text-gray-100 tracking-tight mb-6 md:mt-0">
            Account Setting
          </h1>

          <div className="flex flex-col md:flex-row gap-4 my-10">
            <button
              className={`${
                activeTab === "account"
                  ? "bg-primaryColor"
                  : "border border-primaryColor"
              } px-7 rounded-full tracking-wide py-2 text-xl hover:scale-110 duration-300`}
              onClick={() => handleTabClick("account")}
            >
              Account
            </button>

            <button
              className={`${
                activeTab === "recording"
                  ? "bg-primaryColor"
                  : "border border-primaryColor"
              } px-7 rounded-full tracking-wide py-2 text-xl hover:scale-110 duration-300`}
              onClick={() => handleTabClick("recording")}
            >
              Recording History
            </button>

            <button
              className={`${
                activeTab === "payment"
                  ? "bg-primaryColor"
                  : "border border-primaryColor"
              } px-7 rounded-full tracking-wide py-2 text-xl hover:scale-110 duration-300`}
              onClick={() => handleTabClick("payment")}
            >
              Payment History
            </button>
          </div>

          {activeTab === "account" && <Account />}
          {activeTab === "recording" && <RecordingHistory />}
          {activeTab === "payment" && <PaymentHistory />}
        </div>
      </section>
    </div>
  );
};

export default Setting;
