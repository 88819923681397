import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
  getCountFromServer
} from "firebase/firestore";
import { Link } from "react-router-dom";
import placeholderImage from "../Images/placeholder.jpg";
import Loader from "../components/Loader";

const RecordingHistory = () => {
  const [recHistory, setRecHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastDocument, setLastDocument] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [documentsPerPage] = useState(6);

  const fetchRecording = async () => {
    setIsLoading(true);
    const userUid = auth.currentUser.uid;
    const userRef = doc(db, "History", userUid);
    const recCollectionRef = collection(userRef, "RecordingHistory");

    // Get the total count of documents
    let q = query(recCollectionRef, orderBy("timestamp", "desc"));
    const getDocumentCount = async (q) => {
      try {
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
      } catch (error) {
        console.error("Error getting document count: ", error);
        return 0;
      }
    };

    const countOfDocs = await getDocumentCount(q);
    setTotalDocuments(countOfDocs);

    if (lastDocument && currentPage > 1) {
      q = query(
        recCollectionRef,
        orderBy("timestamp", "desc"),
        startAfter(lastDocument),
        limit(documentsPerPage)
      );
    } else {
      q = query(recCollectionRef, orderBy("timestamp", "desc"), limit(documentsPerPage));
    }

    try {
      const recSnapshot = await getDocs(q);
      const recList = recSnapshot.docs.map((doc) => doc.data());
      setRecHistory((prevHistory) => [...prevHistory, ...recList]);
      if (recSnapshot.docs.length > 0) {
        setLastDocument(recSnapshot.docs[recSnapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching recording history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRecording();
  }, [currentPage]);

  const handleMoreClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const totalPages = Math.ceil(totalDocuments / documentsPerPage);
  const hasMore = currentPage < totalPages;

  return (
    <div className="">
      <div className="w-full gap-4 flex-wrap flex justify-center">
        {isLoading ? (
          <Loader />
        ) : recHistory.length > 0 ? (
          recHistory.map((rec, index) => (
            <div
              key={index}
              className="w-80 p-2 bg-gray-200 rounded-xl cursor-pointer shadow-lg hover:shadow-2xl"
            >
              <Link to={`/settings/${rec.id}`}>
                <img
                  className="object-cover rounded-xl"
                  src={rec.image_thumbnail ? rec.image_thumbnail : placeholderImage}
                  alt="Recording"
                  width={500}
                  height={300}
                />
                <div className="p-2">
                  <h2 className="font-bold text-lg mb-2 text-primaryColor">
                    {rec.recordingName.toString().replace(".webm", "")}
                  </h2>
                  <p className="text-sm text-gray-600">
                    {new Date(rec.timestamp).toLocaleString("en-US", {
                      timeZone: "UTC",
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: false,
                    })}
                  </p>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div>No data to load</div>
        )}
      </div>

      {!isLoading && hasMore && (
        <div className="flex justify-center mt-4">
          <button
            onClick={handleMoreClick}
            className="bg-primaryColor hover:border hover:border-primaryColor text-white font-bold py-2 px-4 rounded"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default RecordingHistory;
