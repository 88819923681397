import React, { useEffect, useState } from "react";
import Wave1 from "../Images/wave.png";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { TiTick } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import { plans, formatDateTimeWithTimeZone } from "../utils/utils";
import Loader from "../components/Loader";

const SuccessPage = () => {
  const location = useLocation();
  const [sessionId, setSessionId] = useState();
  const [isSessionUsed, setIsSessionUsed] = useState(false);
  const [credits, setCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let session_Id = queryParams.get("session_id");
    setSessionId(session_Id);
    let plan_Id = queryParams.get("plan-id");
    // console.log("PlanId", plan_Id);

    const selectedPlan = plans.find((p) => p.id === plan_Id);
    // console.log("Selected Plan", selectedPlan);

    let creditsFromUrl = selectedPlan.credits;
    if (creditsFromUrl) {
      setCredits(parseInt(creditsFromUrl));
    }

    // console.log("Session ID:", sessionId);
    // console.log("Credits:", credits);
  }, [location.search, sessionId, credits]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer sk_test_51OQ4IZAQmRi6xEVdmvcC4h6o8JLsxKVJxIL9iNPb7EEDNnVB6dbuG7wI9jb6hm2OSJNKbFF3D0fjzx4iUmRHpnLd00tVHwfIgA"
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const getData = async (sessionId) => {
      setIsLoading(true); // Start loading
      try {
        const response = await fetch(
          `https://api.stripe.com/v1/checkout/sessions/${sessionId}`,
          requestOptions
        );

        if (!response.ok) {
          console.log("Error in response");
          return;
        }

        const data = await response.json();
    
        const paymentStatus = data.payment_status;
        const amountTotal = data.amount_total;

        if (paymentStatus === "paid") {
          const user = auth.currentUser;

          if (user) {
            const userDocRef = doc(db, "Users", user.uid);
            const userDocSnapshot = await getDoc(userDocRef);

            if (userDocSnapshot.exists()) {
              const userCredits = userDocSnapshot.data().credits;

              const updatedCredits = userCredits + credits;

              const paymentHistoryRef = collection(
                db,
                "History",
                user.uid,
                "PaymentHistory"
              );
              const querySnapshot = await getDocs(paymentHistoryRef);
              const existingSession = querySnapshot.docs.find(
                (doc) => doc.data().stripeCheckoutSessionId === sessionId
              );

              if (existingSession) {
                // console.log("Session ID already used");
                setIsSessionUsed(true);
                return;
              } else {
                const dateTime = formatDateTimeWithTimeZone();
                const paymentHistoryDocRef = await addDoc(paymentHistoryRef, {
                  Amount: amountTotal / 100,
                  paymentStatus: paymentStatus,
                  Credits: credits,
                  timestamp: dateTime,
                  stripeCheckoutSessionId: sessionId,
                });
                // console.log(
                //   "New payment history document ID:",
                //   paymentHistoryDocRef.id
                // );

                await updateDoc(userDocRef, { credits: updatedCredits });
              }
            }
          }
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    if (sessionId) {
      getData(sessionId);
    }
  }, [location.search, sessionId, credits]);

  // console.log("Session isSession Used", isSessionUsed);

  return (
    <section className="bg-secondaryColor text-gray-100 h-screen flex flex-col justify-center px-[10%] py-[6%] z-50 relative">
      <img
        src={Wave1}
        alt="Ellipse"
        className="absolute z-20 bottom-0 right-0 w-2/3 pointer-events-none" // Ensure the image doesn't interfere with user interaction
      />
      {isLoading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : isSessionUsed ? (
        <div className="border w-full md:w-1/2 mx-auto bg-white shadow py-10 rounded-lg">
          <div className="text-center text-red-500 text-sm mt-4">
            This session ID has already been used.
          </div>
          <div className="flex justify-center my-4">
            <Link to="/pricing">
              <button className="bg-primaryColor rounded-full py-2 px-6">
                Retry
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="border w-full md:w-1/2 mx-auto shadow p-10 rounded-lg">
          <div>
            <h1 className="text-center font-medium uppercase text-xl">
              PAYMENT SUCCESSFUL!
            </h1>
            <h2 className="text-center text-gray-300 text-sm">
              Thank you for your purchase!
            </h2>
          </div>
          <div className="md:flex-shrink-0 flex justify-center my-6">
            <div className="h-12 w-12 flex items-center justify-center rounded-full bg-blue-100 text-blue-900">
              <TiTick className="w-1/2 h-full" />
            </div>
          </div>
          <div className="flex justify-center">
            <Link to="/">
              <button className="bg-primaryColor rounded-full py-2 px-6">
                Back to home
              </button>
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

export default SuccessPage;
