 const plans = [
    {
      id: "basic",
      title: "5 Credit",
      price: "10",
      priceId: "price_1PAsgZAQmRi6xEVdR0fdfZEw",
      credits: 5,
    },
    {
      id: "basic_advanced",
      title: "10 Credit",
      price: "4.99",
      priceId: "price_1PBERPAQmRi6xEVdQAQL7J3V",
      credits: 10,
    },
    {
      id: "standard",
      title: "15 Credit",
      price: " 9.99",
      priceId: "price_1PBEOTAQmRi6xEVdbxOQxsgR",
      credits: 15,
    },
    {
      id: "premium",
      title: "20 Credit",
      price: "14.99",
      priceId: "price_1PBEN9AQmRi6xEVdy30JBPCn",
      credits: 20,
    },
  ];

  function formatDateTimeWithTimeZone() {
    const dataTime = new Date().toISOString();
    const dateObject = new Date(dataTime);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString('default', { month: 'long' });
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const timezoneOffsetHours = Math.abs(dateObject.getTimezoneOffset() / 60);
    const timezoneOffsetMinutes = Math.abs(dateObject.getTimezoneOffset() % 60);
    const timezoneSign = dateObject.getTimezoneOffset() < 0 ? '+' : '-';

    const formattedDateTime = `${day} ${month} ${year} ${hours}:${minutes} (UTC${timezoneSign}${timezoneOffsetHours < 10 ? '0' : ''}${timezoneOffsetHours}:${timezoneOffsetMinutes < 10 ? '0' : ''}${timezoneOffsetMinutes})`;

    return formattedDateTime;
}


  export {plans , formatDateTimeWithTimeZone}