import React, { useState } from 'react';
import logo from "../Images/logo.png";
import { useUserAuth } from '../context/UserAuthContext';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const isLoggedIn = () => {
    return user !== null;
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLogoutClick = () => {
    console.log("Logout button clicked");
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    console.log("Dialog close button clicked");
    setShowDialog(false);
  };

  const handleConfirmLogout = async () => {
    setShowDialog(false);
    await handleLogout();
  };

  return (
    <div className='text-white'>
      <nav className="fixed top-0 left-0 w-full py-6 bg-secondaryColor">
        <div className="max-w-[1240px] mx-auto flex justify-between items-center px-6 md:px-0">
          <Link to='/'>
            <img src={logo} alt='logo' className="h-10 animate-bounce" />
          </Link>
         
          <ul className="text-sm tracking-wide items-center flex gap-x-8">
            <li className="hover:scale-125 duration-300 py-1 hover:text-hoverColor">
              <Link to="/">Home</Link>
            </li>
            <li className="hover:scale-125 duration-300 py-1 hover:text-hoverColor">
              <Link to="/pricing">Pricing</Link>
            </li>

            {isLoggedIn() && (
              <>
                <li className="hover:scale-125 duration-300 py-1 hover:text-hoverColor">
                  <Link to="/settings">My Profile</Link>
                </li>

                <div>
                  <button
                    onClick={handleLogoutClick}
                    className="bg-red-500 px-7 rounded-full tracking-wide py-3 hover:scale-110 duration-300"
                  >
                    LOGOUT
                  </button>
                </div>
              </>
            )}

            {!isLoggedIn() && (
              <button
                className="bg-primaryColor px-7 rounded-full tracking-wide py-3 hover:scale-110 duration-300"
              >
                <Link to="/signin">SignIn</Link>
              </button>
            )}
          </ul>
        </div>
      </nav>

      {showDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white rounded-md p-10 text-center">
            <h2 className="text-xl mb-4 text-black">Are you sure you want to logout?</h2>
            <div className="flex justify-center gap-4">
              <button
                onClick={handleConfirmLogout}
                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
              >
                Yes
              </button>
              <button
                onClick={handleDialogClose}
                className="bg-gray-300 py-2 px-4 rounded-md hover:bg-gray-400 transition duration-300"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
