import React, { useState } from "react";
import Ellipse from "../../Images/Ellipse.png";
import Wave3 from "../../Images/wave3.png";
import Wave1 from "../../Images/wave.png";
import googleSvg from "../../Images/google.svg";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      await logIn(email, password);
      toast.success("User Login  Successfully!!", {
        position: "top-center",
      });
      navigate("/");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center bg-[#23304c]">
      <img
        src={Ellipse}
        alt="Ellipse"
        className="z-20 animate-ping w-6 absolute left-24 top-56 hidden lg:block"
      />
      <img
        src={Ellipse}
        alt="Ellipse"
        className="z-20 animate-ping w-6 absolute right-96 top-36 hidden lg:block"
      />
      <img
        src={Ellipse}
        alt="Ellipse"
        className="z-20 animate-ping w-6 absolute left-64 bottom-24 hidden lg:block"
      />
      <img
        src={Ellipse}
        alt="Ellipse"
        className="z-20 animate-ping w-6 absolute right-40 top-64 hidden lg:block"
      />
      <img src={Wave3} alt="Wave3" className="absolute bottom-20 right-36 w-96 hidden lg:block" />
      <img src={Wave1} alt="Wave1" className="absolute z-20 bottom-0 right-0 w-2/3 hidden lg:block" />
      <div className="flex flex-col justify-center p-8 md:p-20 rounded-lg bg-white z-50 max-w-lg w-full mx-4">
        <span className="mb-3 text-4xl font-bold">Welcome back</span>
        <span className="font-light text-gray-400 mb-2">
          Welcome back! Please enter your details
        </span>
        {error && <p className="text-red-500">{error}</p>}
        {loading && <Loader />}
        <form onSubmit={handleSubmit}>
          <div className="py-4">
            <span className="mb-2 text-md">Email</span>
            <input
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
              disabled={loading}
            />
          </div>
          <div className="py-4">
            <span className="mb-2 text-md">Password</span>
            <input
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="w-full p-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
              disabled={loading}
            />
          </div>

          <span className="text-blue-700 text-md text-right">Forgot password?</span>

          <button
            type="submit"
            className={`w-full bg-primaryColor text-white p-2 border border-gray-300 rounded-lg my-6 hover:bg-secondaryColor hover:text-white hover:border hover:border-gray-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Signing in..." : "Sign in"}
          </button>
          <button
            type="button"
            className="w-full border border-gray-300 text-md py-2 rounded-lg mb-6"
            disabled={loading}
          >
            <img src={googleSvg} alt="img" className="w-6 h-6 inline mr-2" />
            Sign in with Google
          </button>
        </form>

        <div className="text-center text-gray-400">
          Don't have an account?
          <span className="text-blue-700">
            {" "}
          
            <Link to="/signup">Sign up for free</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
