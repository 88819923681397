import React, { useState } from "react";
import Wave1 from "../Images/wave.png";
import Wave3 from "../Images/wave3.png";
import Radio, { RadioGroup } from "../components/Radio";
import Navbar from "../components/Navbar";
import { loadStripe } from "@stripe/stripe-js";
import { plans } from "../utils/utils";
import { useUserAuth } from "../context/UserAuthContext"; // Assuming you have a context to get user auth info
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Initialize Stripe
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51OQ4IZAQmRi6xEVdm0NIGR3sGYygPByijHYwO2VB9h3SaOx3F5LZ0bUpAQ0xq6ju08uFk5iUyWSyiFvFYKOZ3Ev300pKXkb9E5"
    );
  }
  return stripePromise;
};

const PricingPage = () => {
  const [plan, setPlan] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const { user } = useUserAuth(); // Get the logged-in user info

  const handlePlanChange = (selectedPlan) => {
    setPlan(selectedPlan);
  };

  const navigate = useNavigate();

  const redirectToCheckout = async () => {
    if (!user) {
      navigate("/signin");
      toast.error("User Account Required!", {
        position: "top-center",
      });
      return;
    }
    setLoading(true);
    setStripeError(null);

    if (!plan) {
      setLoading(false);
      return;
    }

    const selectedPlan = plans.find((p) => p.id === plan);
    // console.log("Selected Plan:", selectedPlan);
    // console.log("Price ID:", selectedPlan.priceId);

    const stripe = await getStripe();
    const stripeResult = await stripe.redirectToCheckout({
      lineItems: [{ price: selectedPlan.priceId, quantity: 1 }],
      mode: "payment",
      successUrl: `${window.location.origin}/#/success?session_id={CHECKOUT_SESSION_ID}&plan-id=${selectedPlan.id}`,
      cancelUrl: `${window.location.origin}/#/cancel`,
      customerEmail: user?.email, 
    });

    if (stripeResult.error) {
      setStripeError(stripeResult.error.message);
      setLoading(false);
    }
  };

  if (stripeError) alert(stripeError);

  return (
    <div className="">
      <section className="bg-secondaryColor min-h-screen flex flex-col md:flex-row items-center px-[10%] py-[6%]">
        <Navbar />
        <img src={Wave3} alt="Ellipse" className="absolute bottom-20 right-36 w-96 hidden md:block" />
        <img src={Wave1} alt="Ellipse" className="absolute z-20 bottom-0 right-0 w-2/3 hidden md:block" />

        <div className="w-full md:w-1/2 lg:w-1/3 mx-auto ">
          <h1 className="font-semibold text-4xl md:text-5xl text-gray-100 tracking-tight my-20 md:my-0 text-center md:text-left">
            Choose Your Credits
          </h1>
          <div className="my-6">
            <RadioGroup value={plan} onChange={(e) => handlePlanChange(e.target.value)}>
              <div className="flex gap-4 justify-center flex-col">
                {plans.map((plan) => (
                  <Radio key={plan.id} value={plan.id}>
                    <Plan title={plan.title} price={plan.price} />
                  </Radio>
                ))}
              </div>
            </RadioGroup>
          </div>

          {plan ? (
            <button
              type="button"
              onClick={redirectToCheckout}
              className="bg-primaryColor text-lg text-white px-7 rounded-full tracking-wide py-3 hover:scale-110 duration-300 block mx-auto"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "BUY"}
            </button>
          ) : (
            <p className="text-white text-center">
              Please select a product to proceed with the checkout.
            </p>
          )}
        </div>

        <div className="w-full md:w-1/2 lg:w-2/3 md:order-1">
          <Navbar />
          <img src={Wave3} alt="Ellipse" className="absolute bottom-20 right-36 w-96 hidden md:block" />
          <img src={Wave1} alt="Ellipse" className="absolute z-20 bottom-0 right-0 w-2/3 hidden md:block" />
        </div>
      </section>
    </div>
  );
};

function Plan({ title, price }) {
  return (
    <div className="flex gap-10 items-center">
      <div>
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>
      <span className="ml-auto font-medium">${price}</span>
    </div>
  );
}

export default PricingPage;
