import React, { useEffect, useRef, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; // Make sure this path is correct
import { MdModeEdit } from "react-icons/md";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import imageCompression from 'browser-image-compression';
import Loader from "../components/Loader";

const Account = () => {
  const { user } = useUserAuth();
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user || !user.uid) {
        console.error("User object or user UID is not available.");
        setIsLoading(true);
        return;
      }

      setIsLoading(true);
      const docRef = doc(db, "Users", user.uid);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserDetails(userData);
          setSelectedImage(userData.imageUrl);
          setNewUsername(userData.username);
        } else {
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [user]);

  // Inside your component
  const storage = getStorage();

  const handleUpload = async (e) => {
    if (!storage) {
      console.error("Firebase storage is not initialized.");
      return;
    }

    if (!e.target.files || e.target.files.length === 0) {
      console.error("No file selected.");
      return;
    }

    const file = e.target.files[0];
    setImageUploadLoading(true);

    try {
      // Compress the image
      const options = {
        maxSizeMB: 1,          // Maximum size in MB
        maxWidthOrHeight: 800, // Maximum width or height
        useWebWorker: true,    // Use multi-threading for performance
      };
      const compressedFile = await imageCompression(file, options);

      const storageRef = ref(storage, `profile-images/${file.name}`);
      await uploadBytes(storageRef, compressedFile);
      const url = await getDownloadURL(storageRef);
      setSelectedImage(url);
      const userRef = doc(db, "Users", user.uid);
      await updateDoc(userRef, {
        imageUrl: url,
      });
     
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setImageUploadLoading(false);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleUpdateProfile = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = async () => {
    const userRef = doc(db, "Users", user.uid);
    await updateDoc(userRef, {
      username: newUsername,
    });
    handleCloseModal();
    window.location.reload();
  };

  return (
    <div className="flex flex-col gap-4 w-1/2 rounded-md ">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="mt-6">
            <div>
              {selectedImage ? (
                <div className="relative w-16">
                  <img
                    src={selectedImage}
                    alt="Profile"
                    className="rounded-full h-16 w-16 object-cover"
                  />
                  {imageUploadLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 rounded-full">
                      <Loader />
                    </div>
                  )}
                  <MdModeEdit
                    icon="edit"
                    onClick={triggerFileInput}
                    className="cursor-pointer absolute bottom-0 right-0 border bg-white rounded-full text-primaryColor h-6 w-6 p-1"
                  />
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleUpload}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    id="imageUpload"
                  />
                  <button
                    onClick={triggerFileInput}
                    className="bg-primaryColor px-7 rounded-full tracking-wide py-3 text-xs hover:scale-110 duration-300"
                  >
                    Upload Image
                  </button>
                </div>
              )}
            </div>
          </div>

          <input
            type="file"
            accept="image/*"
            onChange={handleUpload}
            ref={fileInputRef}
            style={{ display: "none" }}
            id="imageUpload"
          />

          <div className="flex gap-4">
            <h1>Full Name :</h1>
            <div>
              <h1>{userDetails?.username}</h1>
            </div>
          </div>

          <div className="flex gap-4">
            <h1>Email :</h1>
            <div>
              <h1>{userDetails?.email}</h1>
            </div>
          </div>

          <div className="flex gap-4">
            <h1>Credits :</h1>
            <div>
              <h1>{userDetails?.credits}</h1>
            </div>
          </div>

          <div>
            <button
              onClick={handleUpdateProfile}
              className="bg-primaryColor px-7 rounded-full tracking-wide py-3 text-xs hover:scale-110 duration-300"
            >
              Edit Profile
            </button>
          </div>

          {/* Modal for Editing Profile */}
          <div
            className={`modal ${
              showModal ? "block" : "hidden"
            } fixed z-10 inset-0 overflow-y-auto`}
          >
            <div className="flex items-center justify-center min-h-screen">
              <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
              <div className="modal-container bg-white w-1/2 md:max-w-lg mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                  <svg
                    onClick={handleCloseModal}
                    className="fill-current text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path
                      id="Close"
                      d="M10.294 8.884l5.46-5.46c.195-.195.195-.512 0-.707L14.74 1.295c-.195-.195-.512-.195-.707 0l-5.46 5.46-5.46-5.46c-.195-.195-.512-.195-.707 0l-1.414 1.414c-.195.195-.195.512 0 .707l5.46 5.46-5.46 5.46c-.195.195-.195.512 0 .707l1.414 1.414c.195.195.512.195.707 0l5.46-5.46 5.46 5.46c.195.195.512.195.707 0l1.414-1.414c.195-.195.195-.512 0-.707l-5.46-5.46z"
                    ></path>
                  </svg>
                </div>

                <div className="modal-content py-4 text-left px-6">
                  <div className="modal-header">
                    <h2 className="text-2xl font-bold text-secondaryColor my-4">
                      Edit Profile
                    </h2>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSaveChanges();
                    }}
                  >
                    <div className="modal-body">
                      <label className="block">
                        <span className="text-gray-700">Full Name:</span>
                        <input
                          type="text"
                          value={newUsername}
                          onChange={(e) => setNewUsername(e.target.value)}
                          className="form-input mt-1 block w-full text-black  border border-primaryColor rounded-md p-2"
                          placeholder="Username"
                        />
                      </label>
                    </div>
                    <div className="modal-footer mt-4 flex justify-end">
                      <button
                        onClick={handleCloseModal}
                        className="border border-red-500 text-red-500  py-2 px-4 rounded-md  hover:bg-red-600 hover:text-white transition duration-300"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primaryColor text-white py-2 px-4 rounded-md ml-2 hover:bg-primaryColor-dark transition duration-300"
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Account;
