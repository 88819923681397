import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import AWS from "aws-sdk";
import html2canvas from "html2canvas";
import { auth, db } from "../firebase";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { formatDateTimeWithTimeZone } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const ViewRecordingScreen = ({ videoURL,videoBlob }) => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [credits, setCredits] = useState(null);
  const [creditsLoading, setCreditsLoading] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false); // Track user login status

  const navigate = useNavigate();

  useEffect(() => {
    if (videoURL) {
      videoRef.current.src = videoURL;
      videoRef.current.srcObject = null;
    }
  }, [videoURL]);

  useEffect(() => {
    const fetchCredits = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserLoggedIn(true); // User is logged in
        const userRef = doc(db, "Users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setCredits(userSnap.data().credits);
        } else {
          console.error("No such user!");
        }
      } else {
        setUserLoggedIn(false); // User is not logged in
      }
      setCreditsLoading(false);
    };
    fetchCredits();
  }, []);

  const handleDownload = () => {
    const timestamp = getCurrentDateTime();
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = videoURL;
    a.download = `uzr-screen-recorder-${timestamp}.webm`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(videoURL);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().replace(/[:.]/g, "-");
  };

  const CapturePreview = async () => {
    try {
      const canvas = await html2canvas(
        document.querySelector("#capture-preview")
      );
      return new Promise((resolve, reject) => {
        canvas.toBlob(async (blob) => {
          if (!blob) {
            reject("Failed to create blob from canvas");
            return;
          }

          const formData = new FormData();
          formData.append(
            "image",
            blob,
            `thumbnail-${getCurrentDateTime()}.jpg`
          );

          try {
            const response = await fetch(
              "https://api.imgbb.com/1/upload?key=851b550eaf1ef017cc79615958778d60",
              {
                method: "POST",
                body: formData,
              }
            );

            if (!response.ok) {
              const errorText = await response.text();
              reject(
                `Thumbnail upload failed with status ${response.status}: ${errorText}`
              );
              return;
            }

            const result = await response.json();
            if (result.data && result.data.url) {
              resolve(result.data.url);
            } else {
              reject("Thumbnail upload failed: Invalid response data");
            }
          } catch (error) {
            reject(`Thumbnail upload error: ${error.message}`);
          }
        }, "image/jpeg");
      });
    } catch (error) {
      return Promise.reject(`html2canvas error: ${error.message}`);
    }
  };

  // console.log(thumbnailUrl)
  // console.log("VB",videoBlob)
  const saveRecording = async () => {
    if (!videoURL) {
      console.error("No video URL found");
      return;
    }

    // console.log("videourl",videoURL)

   

    try {
  

      const timestamp = getCurrentDateTime();
      const fileName = `uzr-screen-recorder-${timestamp}.webm`;
      const file = new File([videoBlob], fileName, { type: "video/webm" });

      const S3_BUCKET = "multivendorswedenuzrtech";
      const REGION = "eu-north-1";

      AWS.config.update({
        accessKeyId: "AKIASWMWFEAPJOC4QTS7",
        secretAccessKey: "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",
        region: REGION,
      });

      const s3 = new AWS.S3();

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
      };

      setLoading(true);
      const uploadResult = await s3.upload(params).promise();
      // console.log("File uploaded successfully:", uploadResult.Location);

      let thumbnailUrl = await CapturePreview();
      setThumbnailUrl(thumbnailUrl);
      // console.log("Thumbnail URL:", thumbnailUrl);

      const user = auth.currentUser;
      const dateTime = formatDateTimeWithTimeZone();
      const recordingHistoryRef = collection(
        db,
        "History",
        user.uid,
        "RecordingHistory"
      );

      const docRef = await addDoc(recordingHistoryRef, {
        recordingName: fileName,
        recordingUrl: uploadResult.Location,
        image_thumbnail: thumbnailUrl,
        timestamp: dateTime,
      });

      await updateDoc(docRef, {
        id: docRef.id,
      });

      const userRef = doc(db, "Users", user.uid);
      await updateDoc(userRef, {
        credits: credits - 1,
      });

      setCredits(credits - 1);
      setLoading(false);
      toast.success("Recording saved successfully!", {
        position: "top-center",
      });
      navigate("/settings", { state: { activeTab: "recording" } });
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
    }
  };

  return (
    <div className="text-gray-100">
    <section className="bg-secondaryColor min-h-screen flex flex-col md:flex-row items-center md:justify-center gap-10 md:gap-20 px-4 md:px-10 py-6 md:py-8">
      {/* Navbar */}
      <Navbar />
      
      {/* Video */}
      <div className="flex-1 mt-20 md:mt-0">
        <h1 className="font-semibold text-4xl text-gray-100 tracking-tight mb-6 md:mt-0">Recording Preview</h1>
        <video
          id="capture-preview"
          ref={videoRef}
          width={800}
        
          height={300}
          controls
          autoPlay
          style={{ position: "relative", zIndex: 0 }}
        />
      </div>
  
      {/* Buttons */}
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="my-2 md:my-4">
          <button
            onClick={handleDownload}
            className="bg-primaryColor px-7 rounded-full tracking-wide py-3 text-lg hover:scale-110 duration-300"
          >
            DOWNLOAD VIDEO
          </button>
        </div>
        {userLoggedIn && !creditsLoading && credits !== null && credits > 0 ? (
          <div className="my-2 md:my-4">
            <button
              onClick={saveRecording}
              className="bg-primaryColor px-7 rounded-full tracking-wide py-3 text-lg hover:scale-110 duration-300"
            >
              {loading ? "SAVING..." : "SAVE RECORDING"}
            </button>
          </div>
        ) : (
         null
        )}
      </div>
    </section>
    {loading && <Loader />}
  </div>
  
  
  );
};

export default ViewRecordingScreen;
